import React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')`
	display: flex;
	justify-content: center;

	@media (max-width: 1100px) {
		flex-direction: column;
		div {
			margin-bottom: 45px;
		}
	}
`;

function ItemBlockContainer(props) {
	return <Wrapper>{props.children}</Wrapper>;
}

export default ItemBlockContainer;
