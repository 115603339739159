import React, { useState } from 'react';
import styled from 'styled-components';
import FormThankYou from './FormThankYou';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const FormWrapper = styled.form`
	width: 100%;
	background-color: rgb(230, 230, 230);
	padding: 40px;
	display: flex;
	justify-content: space-between;

	textarea {
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: 0;
		resize: none;
		width: 100%;
		height: 90px;
		margin-top: 10px;
		font-size: 19px;
		outline: none;
		border: none;
		font-family: default;
		:focus {
			border: 2px #fe5000 solid;
		}

		padding: 5px;

		::placeholder {
			font-size: 16px;
		}
	}

	input {
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		height: 30px;
		font-size: 19px;
		outline: none;
		border: none;
		:focus {
			border: 2px #fe5000 solid;
		}

		padding: 5px;

		::placeholder {
			font-size: 16px;
		}
	}

	.orgInput {
		margin-top: 10px;
	}

	.fullWidth {
		width: 100%;
	}

	.sectionHead {
		width: 100%;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 21px;
	}

	.marginTop {
		margin-top: 50px;
	}

	.contactInfoField {
		width: 100%;
		display: flex;
		justify-content: space-between;
		input {
			width: 70%;
			margin-left: 10px;
		}
		margin-top: 15px;
		align-items: baseline;
		label {
			text-transform: uppercase;
		}
	}

	.formColumn {
		width: 47.5%;
	}

	.asterisk {
		color: #fe5000;
	}

	.submissionArea {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		input {
			background-color: #fe5000;
			color: #fff;
			padding: 0 30px;
			text-transform: uppercase;
			font-size: 21px;
			height: 74px;
			font-weight: 600;
			box-sizing: border-box;
			border: 4px solid #fe5000;
			cursor: pointer;
			:disabled {
				opacity: 0.5;
			}
			:enabled {
				&:hover {
					background-color: #fff;
					color: #fe5000;
				}
			}
		}
	}

	@media (max-width: 1350px) {
		flex-direction: column;
		.formColumn {
			width: 100%;
		}
		.first {
			margin-bottom: 40px;
		}
		.submissionArea {
			justify-content: center;
			input {
				margin-left: 10px;
			}
		}
		textarea {
			height: 180px;
		}
	}

	@media (max-width: 650px) {
		.submissionArea {
			flex-direction: column;
			input {
				margin-left: 0;
				margin-top: 20px;
			}
		}
	}
`;

const TEXT_AREA_PLACEHOLDER =
	'Want to tell us about your organization and how Haptix Performance Group and Sparta Science may be able to help your athletes?';

const defaultValues = {
	message: '',
	email: '',
	name: '',
	organizationName: '',
};

function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function Form(props) {
	const [formValues, setFormValues] = useState(defaultValues);
	const [recaptchaToken, setRecaptchaToken] = useState(null);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const handleReset = () => {
		setFormValues(defaultValues);
		setRecaptchaToken(null);
		setLoading(false);
		setSubmitted(false);
	};

	const handleChange = (field, e) => {
		const value = e.target.value;
		setFormValues((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!recaptchaToken) return;
		setLoading(true);
		axios({
			method: 'post',
			url: 'https://us-central1-haptix-site.cloudfunctions.net/submitForm',
			data: {
				form: formValues,
				formType: '_GENERAL',
				recaptchaToken,
			},
		});
		await timeout(500);
		window.dataLayer.push({
			event: 'contact-form-submit',
		});
		setLoading(false);
		setSubmitted(true);
	};

	const handleRecaptchaChange = (token) => {
		if (!token) {
			setRecaptchaToken(null);
			return;
		}
		setRecaptchaToken(token);
	};

	if (submitted) return <FormThankYou handleReset={handleReset} />;

	return (
		<FormWrapper onSubmit={handleSubmit} id='contact-form'>
			<div className='formColumn first'>
				<label>
					<span className='sectionHead'>Organization Name</span>
					<input
						disabled={loading}
						placeholder='e.g., Haptix University'
						maxLength={64}
						className='fullWidth orgInput'
						type='text'
						value={formValues.organizationName}
						onChange={(e) => handleChange('organizationName', e)}
					></input>
				</label>
				<div className='marginTop'>
					<span className='sectionHead'>Contact Info</span>
					<div className='contactInfoField'>
						<label>
							Name<span className='asterisk'>*</span>
						</label>
						<input
							disabled={loading}
							maxLength={64}
							required={true}
							type='text'
							value={formValues.name}
							onChange={(e) => handleChange('name', e)}
						></input>
					</div>
					<div className='contactInfoField'>
						<label>
							Email<span className='asterisk'>*</span>
						</label>
						<input
							maxLength={64}
							disabled={loading}
							required={true}
							type='email'
							value={formValues.email}
							onChange={(e) => handleChange('email', e)}
						></input>
					</div>
				</div>
			</div>
			<div className='formColumn second'>
				<label>
					<span className='sectionHead'>Anything We Should Know?</span>
				</label>
				<textarea
					disabled={loading}
					placeholder={TEXT_AREA_PLACEHOLDER}
					maxLength={4096}
					value={formValues.message}
					onChange={(e) => handleChange('message', e)}
				></textarea>
				<div className='submissionArea'>
					<ReCAPTCHA
						sitekey='6Lciv8oZAAAAACj_gavk5frOAUp2oy_2fUh1SFXU'
						onChange={handleRecaptchaChange}
					/>
					<input
						type='submit'
						value='Submit'
						disabled={loading || !recaptchaToken}
						id='submit-button'
					/>
				</div>
			</div>
		</FormWrapper>
	);
}

export default Form;
