import React from 'react';
import styled from 'styled-components';

import Img from './partner_w_gradient.jpg';

const Wrapper = styled('div')`
	width: 100%;
	position: relative;

	background-image: url(${Img});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	padding-top: calc(47 / 980 * 100%);
	padding-bottom: calc(47 / 980 * 100%);
	padding-left: calc(115 / 1400 * 100%);
	padding-right: calc(400 / 1400 * 100%);

	> div {
		color: #fff;
	}

	h2 {
		font-size: 48px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	p {
		font-size: 18px;
		font-weight: 400;
	}

	@media (max-width: 880px) {
		background-position-x: 80%;
	}

	@media (max-width: 500px) {
		padding-right: calc(115 / 1400 * 100%);
		h2 {
			font-size: 10vw;
		}
	}
`;

function PartnerLockUp(props) {
	return (
		<Wrapper>
			<div>
				<h2>A Powerful Partnership</h2>
				<p>
					As the delivery partner for Sparta Science, Haptix Performance Group
					is committed to making this cutting-edge technology accessible and
					affordable, which means providing our clients with the exact services
					and data they need, when they need them.
				</p>
			</div>
		</Wrapper>
	);
}

export default PartnerLockUp;
