import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import assess from './icon-assess.png';
import diagnose from './icon-diagnose.png';
import prescribe from './icon-prescribe.png';

function getIconSrc(type) {
	switch (type) {
		case 'assess':
			return { w: 152, h: 88, src: assess };
		case 'diagnose':
			return { w: 109, h: 118, src: diagnose };
		case 'prescribe':
			return { w: 96, h: 124, src: prescribe };
		default:
			return { w: 108, h: 118, src: 'https://via.placeholder.com/108x118' };
	}
}

const StyledImg = styled('img')`
	width: ${(props) => props.w}px;
	height: ${(props) => props.h}px;
`;

function Icon(props) {
	const { type } = props;

	let { w, h, src } = getIconSrc(type);

	return <StyledImg src={src} w={w} h={h} />;
}

Icon.propTypes = {
	/**
	 * The type of icon
	 */
	type: PropTypes.string.isRequired,
};

export default Icon;
