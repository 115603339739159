import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	background-color: rgb(48, 107, 170);
	height: 300px;
	text-transform: uppercase;
	text-align: center;
	color: white;
	flex-direction: column;
	display: flex;
	justify-content: center;
	h1 {
		margin-bottom: 5px;
	}

	#reset-form-button {
		position: absolute;
		top: 30px;
		right: 30px;
		outline: 3px white solid;
		height: 30px;
		width: 30px;

		.line1,
		.line2 {
			position: absolute;
			background-color: white;
			height: 30px;
			width: 3px;
		}

		.line1 {
			transform: rotate(45deg);
			left: 47%;
		}

		.line2 {
			transform: rotate(135deg);
			left: 47%;
		}

		cursor: pointer;

		:hover {
			background-color: white;
			.line1,
			.line2 {
				background-color: rgb(48, 107, 170);
			}
		}
	}
`;

const FormThankYou = ({ handleReset }) => {
	return (
		<Wrapper>
			<h1>Thanks for Reaching Out.</h1>
			<p>Someone Will Respond to your Request Very Soon.</p>
			<div id='reset-form-button' onClick={handleReset}>
				<div className='line1' />
				<div className='line2' />
			</div>
		</Wrapper>
	);
};

export default FormThankYou;
