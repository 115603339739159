import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../../_atoms/Icon';

const Wrapper = styled('div')`
	min-width: 200px;
	min-height: 200px;
	text-align: center;
	flex-grow: 1;

	figure {
		height: 130px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
	}
`;

const Content = styled('section')`
	border-top: 4px solid #000;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 4px;
		height: 24px;
		background-color: #000;
		position: absolute;
		top: 0px;
		left: 50%;
		margin-left: -2px;
	}

	h2 {
		margin-top: 32px;
		text-transform: uppercase;
		margin-bottom: 12px;
		font-size: 26px;
		font-weight: bold;
	}
	p {
		font-size: 16px;
		margin: 0 auto;
		max-width: 280px;
	}
`;

function IconBlock(props) {
	const { title, copy, type } = props;

	return (
		<Wrapper>
			<figure>
				<Icon type={type} />
			</figure>
			<Content>
				<h2>{title}</h2>
				<p>{copy}</p>
			</Content>
		</Wrapper>
	);
}

IconBlock.propTypes = {
	/**
	 * The title of the block
	 */
	title: PropTypes.string.isRequired,
	/**
	 * the copy of the block
	 */
	copy: PropTypes.string.isRequired,
};

export default IconBlock;
