import React from 'react';
import styled from 'styled-components';

const Cta = styled('button')`
	background-color: #fe5000;
	color: #fff;
	padding: 0 50px;
	text-transform: uppercase;
	font-size: 21px;

	height: 60px;
	font-weight: 500;
	box-sizing: border-box;
	border: 4px solid #fe5000;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 0);
		color: #fe5000;
	}
`;

function CtaButton(props) {
	const { text, clickCallback } = props;

	const handleClick = (e) => {
		e.preventDefault();
		clickCallback();
	};

	return <Cta onClick={handleClick}>{text}</Cta>;
}

export default CtaButton;
