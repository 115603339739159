import React from 'react';
import styled from 'styled-components';
import HeroBackground from './hero_background.jpg';
//import PartnerLockup from './official-partner-logo.png';
//import HaptixLogo from './haptix-logo.png';

import CtaButton from '../../_atoms/CtaButton';

const Hero = styled('div')`
	width: 100%;
	position: relative;

	> img {
		width: 100%;
		height: auto;
	}

	> div {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding-left: 5%;
		padding-top: 28%;
	}

	h1 {
		color: #fff;
		font-size: 30px;
		margin-top: 0;
		margin-bottom: 50px;
		text-transform: uppercase;
		line-height: 1.2em;
		font-weight: 600;
	}

	@media (max-width: 1160px) {
		header {
			margin-bottom: 30px;
		}
		> div {
			position: relative;
			padding: 10%;
			padding-top: 80px;

			header {
				width: 100%;
				button {
					margin: 0;
					position: absolute;
					left: 50%;
					-ms-transform: translate(-50%);
					transform: translate(-50%);
					white-space: no-wrap;
				}
			}
			h1 {
				color: black;
				margin: 0;
				margin-bottom: 25px;
				text-align: center;
				color: #547aa8;
				br {
					display: none;
				}
			}
		}
	}
`;

function HeroLockUp(props) {
	const handleLearnMoreClick = () => {
		const el = document.getElementById('contact-form');
		el.scrollIntoView({ behavior: 'smooth' });
		//SmoothVerticalScrolling(el, 500, 'top');
	};

	return (
		<Hero>
			<img src={HeroBackground} alt='Fitness woman' />
			<div>
				<header>
					<h1>
						Increase resilience,
						<br /> reduce injuries and
						<br /> optimize performance.
					</h1>
					<CtaButton
						id='learn-more-button'
						text='Learn More'
						clickCallback={handleLearnMoreClick}
					/>
				</header>
			</div>
		</Hero>
	);
}

export default HeroLockUp;
