import React from 'react';
import ReactDOM from 'react-dom';

import styled, { createGlobalStyle } from 'styled-components';

import HeroLockUp from './components/_molecules/HeroLockUp';
import PartnerLockUp from './components/_molecules/PartnerLockUp';
import IconBlockContainer from './components/_organisms/ItemBlockContainer';
import IconBlock from './components/_molecules/IconBlock';
import Form from './components/_organisms/Form';
import Inset from './components/_utilities/Inset';
import VSpacer from './components/_utilities/VSpacer';
import smoothscroll from 'smoothscroll-polyfill';

import TagManager from 'react-gtm-module';

TagManager.initialize({
	gtmId: 'GTM-PL4SKMF',
});

smoothscroll.polyfill();

const GlobalStyle = createGlobalStyle`
	html {
		scroll-behavior: smooth;
	}
	body
	{
		margin: 0;
		font-family: 'Montserrat', 'Proxima Nova', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		box-sizing: border-box;
		* > * {
			font-family: 'Montserrat', 'Proxima Nova', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			box-sizing: border-box;
		}
	}
`;

const Wrapper = styled('main')`
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;

	p.centered {
		font-size: 19px;
		text-align: center;
	}
`;

export default function App() {
	return (
		<>
			<GlobalStyle />
			<Home />
		</>
	);
}

function Home() {
	return (
		<Wrapper>
			<HeroLockUp />
			<VSpacer />
			<Inset>
				<p className='centered'>
					Sparta Science optimizes health and performance for{' '}
					<b>athletic departments</b> with evidence-based movement scans and
					data-driven exercise prescriptions that increase resilience and
					minimize injury risk.
				</p>
				<VSpacer />
				<IconBlockContainer>
					<IconBlock
						title='Assess'
						copy='Validated scientific assessments determine an athlete’s strengths and weaknesses.
						'
						type='assess'
					/>
					<IconBlock
						title='Diagnose'
						copy='The Sparta Software compares an athlete’s results to our database and delivers actionable insights.'
						type='diagnose'
					/>
					<IconBlock
						title='Prescribe'
						copy='Validated plans are tailored to each athlete to reduce injury and improve performance.'
						type='prescribe'
					/>
				</IconBlockContainer>
			</Inset>
			<VSpacer height='140px' />
			<PartnerLockUp />
			<VSpacer />
			<Inset>
				<p className='centered'>
					So, what does your athletic department need? Fill out the information
					below and we'll let you know exactly how Haptix Performance Group can
					help your athletes stay healthy and conditioned.
				</p>
				<VSpacer />
				<Form />
			</Inset>

			<VSpacer height='100px' />
		</Wrapper>
	);
}
// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));
