import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 80%;
	margin: 0 auto;

	@media (max-width: 650px) {
		width: 90%;
	}
`;

const Inset = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Inset;
